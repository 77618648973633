<template>
  <UiPage
    v-if="data"
    :titleTemplate="data.form.name"
    class="form-page"
  >
    <template #content>
      <h2>
        {{ data.form.name }}
      </h2>

      <div class="form-page__grid">
        <div class="form-page__grid__start">
          <div class="form-page__grid__start__banner">
            <img :src="data.form.image_url">
          </div>

          <Html
            v-if="data.form.text"
            :value="data.form.text"
            class="form-page__grid__start__text"
          />

          <div
            v-for="(item, index) in data.fields"
            :key="item.id"
            class="form-page__grid__start__item"
          >
            <div
              class="form-page__grid__start__item__anchor"
              :id="item.id"
            />

            <div
              v-if="item.required"
              class="form-page__grid__start__item__required"
              :class="{
                error: error.indexOf(item.id) !== -1
              }"
            >
              * — {{ $t('views.default.forms.required') }}
            </div>

            <div class="form-page__grid__start__item__name">
              {{ index + 1 }}. {{ item.name }}
            </div>

            <div class="form-page__grid__start__item__content">
              <input
                v-if="item.type === 'input'"
                v-model="form[item.id]"
                class="form-page__grid__start__item__content__input"
                type="text"
                :placeholder="$t('views.default.forms.placeholder')"
                max="255"
                maxlength="255"
              >

              <textarea
                v-if="item.type === 'textarea'"
                v-model="form[item.id]"
                class="form-page__grid__start__item__content__input textarea"
                :placeholder="$t('views.default.forms.placeholder')"
                maxlength="2000"
              />

              <template
                v-if="item.type === 'radio' || item.type === 'checkbox'"
              >
                <div
                  v-for="input in item.values"
                  :key="input.id"
                  class="form-page__grid__start__item__content__label"
                >
                  <input
                    v-model="form[item.id]"
                    :type="item.type"
                    :value="input.name"
                    :id="`${item.id}__${input.id}`"
                  >

                  <label :for="`${item.id}__${input.id}`">
                    <div class="form-page__grid__start__item__content__label__indicator">
                      <svg
                        v-if="item.type === 'checkbox'"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none" viewBox="0 0 24 24"
                        stroke-width="2" stroke="currentColor"
                      >
                        <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                      </svg>
                    </div>

                    {{ input.name }}
                  </label>
                </div>

                <div
                  v-if="item.type === 'radio'"
                  class="form-page__grid__start__item__content__label"
                >
                  <input
                    v-model="form[item.id]"
                    :type="item.type"
                    value="$custom"
                    :id="`${item.id}__custom`"
                    class="custom"
                  >

                  <label :for="`${item.id}__custom`">
                    <div class="form-page__grid__start__item__content__label__indicator">
                      <svg
                        v-if="item.type === 'checkbox'"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none" viewBox="0 0 24 24"
                        stroke-width="2" stroke="currentColor"
                      >
                        <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                      </svg>
                    </div>

                    {{ $t('views.default.forms.custom') }}
                  </label>

                  <textarea
                    v-model="form[`$custom_${item.id}`]"
                    class="form-page__grid__start__item__content__input textarea"
                    :placeholder="$t('views.default.forms.placeholder')"
                    maxlength="2000"
                  />
                </div>
              </template>
            </div>
          </div>

          <div class="form-page__grid__start__bottom">
            <BlueButton
              :text="$t('views.default.forms.send')"
              class="form-page__grid__start__bottom__button"
              :loading="loading"
              :disabled="loading"
              @click="submit()"
            />

            <div
              class="form-page__grid__start__bottom__button text"
              @click="setDefaultForm()"
            >
              {{ $t('views.default.forms.clear') }}
            </div>
          </div>
        </div>

        <div class="form-page__grid__end">
          <AuthSupport
            class="form-page__grid__end__sticky"
          />
        </div>
      </div>
    </template>
  </UiPage>
</template>

<script>
import UiPage from '@/ui/Page.vue'
import AuthSupport from '@/ui/AuthSupport.vue'
import Html from '@/ui/Html.vue'
import BlueButton from '@/ui/buttons/BlueButton.vue'

export default {
  components: {
    UiPage, AuthSupport, Html, BlueButton
  },
  data () {
    return {
      loading: false,
      data: null,
      form: {},
      error: []
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    async fetch () {
      try {
        const response = await this.$axios.get(`private/forms/${this.$route.params.slug}`)

        if (response.status === 200) {
          this.data = response.data.data
          this.setDefaultForm()
        }
      } catch (error) {
        this.$store.commit('SET_ERROR', error)
      }
    },
    async submit () {
      this.error = []

      const fields = this.data.fields
      const form = this.form

      const data = {}

      for (const field of fields) {
        const value = form[field.id] === '$custom' ? form[`$custom_${field.id}`] : form[field.id]

        if (field.required && (value?.length === 0 || !value)) {
          this.error.push(field.id)
        } else {
          if (typeof value === 'object') {
            data[field.id] = value.join(' | ')
          } else {
            data[field.id] = value
          }
        }
      }

      try {
        this.loading = true

        if (this.error.length > 0) {
          document.getElementById(this.error[0]).scrollIntoView({
            behavior: 'smooth'
          })
        } else {
          const response = await this.$axios.post(
            `private/forms/${this.$route.params.slug}`, data)

          if (response.status === 200) {
            setTimeout(() => {
              this.$pop.up({
                name: 'successfully',
                text: this.$t('views.default.forms.success')
              })
            }, 500)

            this.$router.push({ name: 'Main' })
          }
        }
      } catch (error) {
        throw new Error(error)
      } finally {
        this.loading = false
      }
    },
    setDefaultForm () {
      this.form = {}

      for (const key in this.data.fields) {
        if (this.data.fields[key].type === 'checkbox') {
          this.form[this.data.fields[key].id] = []
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-page {
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;

  @media screen and (max-width: 768px) {
    padding: 0 17px;
  }

  h2 {
    margin-bottom: 30px;
    font-size: 32px;
    font-family: SemiBold;
    line-height: 120%;
    color: #202539;

    @media screen and (max-width: 768px) {
      font-size: 26px;
    }
  }

  .form-page__grid {
    display: grid;
    grid-template-columns: auto 300px;
    gap: 20px;

    @media screen and (max-width: 1280px) {
      grid-template-columns: auto;
    }

    .form-page__grid__start {
      .form-page__grid__start__banner {
        width: 100%;
        margin-bottom: 40px;

        img {
          max-height: 270px;
          width: 100%;
          object-fit: cover;
        }
      }

      .form-page__grid__start__text {
        margin-bottom: 20px;
      }

      .form-page__grid__start__item {
        position: relative;
        margin-bottom: 20px;
        padding: 30px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        background: #FFFFFF;
        box-shadow: 0px 5px 30px rgba(0, 0, 0, .06);
        border-radius: 15px;

        .form-page__grid__start__item__anchor {
          position: absolute;
          top: -20px;
          left: 0;
        }

        .form-page__grid__start__item__required {
          font-size: 14px;
          line-height: 100%;
          color: #787C8D;

          &.error {
            color: #DA2828;
          }
        }

        .form-page__grid__start__item__name {
          font-size: 16px;
          font-family: Medium;
          line-height: 140%;
          color: #202539;
        }

        .form-page__grid__start__item__content {
          display: flex;
          flex-direction: column;
          gap: 15px;

          .form-page__grid__start__item__content__input {
            padding: 20px;
            border-radius: 5px;
            background: #F0F2F8;
            border: 1px solid transparent;
            outline: none !important;
            font-size: 16px;
            line-height: 140%;
            color: #202539;
            transition: .2s;

            &:focus {
              border: 1px solid #2F57E9;
            }

            &::placeholder {
              color: #9CA3B8;
            }

            &.textarea {
              height: 172px;
              resize: none;
            }
          }

          .form-page__grid__start__item__content__label {
            position: relative;

            input {
              position: absolute;
              margin: 0;
              height: 1px;
              width: 1px;
              opacity: 0;

              &[type="radio"] {
                & ~ label {
                  .form-page__grid__start__item__content__label__indicator {
                    border-radius: 50%;
                  }
                }

                &:checked ~ label {
                  .form-page__grid__start__item__content__label__indicator {
                    border: 5px solid #2F57E9;
                  }
                }
              }

              &[type="checkbox"] {
                & ~ label {
                  .form-page__grid__start__item__content__label__indicator {
                    border-radius: 2px;
                  }
                }

                &:checked ~ label {
                  .form-page__grid__start__item__content__label__indicator {
                    border: 1px solid #2F57E9;
                    background: #2F57E9;

                    svg {
                      opacity: 1;

                      path {
                        stroke: #fff;
                      }
                    }
                  }
                }
              }

              &.custom {
                & ~ textarea {
                  display: none;
                }

                &:checked ~ textarea {
                  display: block;
                }
              }
            }

            label {
              display: flex;
              align-items: center;
              gap: 6px;
              font-size: 14px;
              line-height: 100%;
              color: #202539;
              font-family: Medium;

              .form-page__grid__start__item__content__label__indicator {
                height: 14px;
                min-height: 14px;
                width: 14px;
                min-width: 14px;
                display: flex;
                align-items: center;
                border: 1px solid #787C8D;
                transition: .2s;

                @media screen and (max-width: 768px) {
                  height: 24px;
                  min-height: 24px;
                  width: 24px;
                  min-width: 24px;
                }

                &:hover {
                  border: 1px solid #2F57E9;
                }

                svg {
                  height: 14px;
                  width: 14px;
                  margin: 0 auto;
                  opacity: 0;
                  transition: inherit;

                  * {
                    transition: inherit;
                  }
                }
              }
            }

            textarea {
              margin-top: 15px;
              width: 100%;
            }
          }
        }
      }

      .form-page__grid__start__bottom {
        padding-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: 768px) {
          flex-direction: column;
          gap: 25px;
        }

        .form-page__grid__start__bottom__button {
          max-width: 230px;
          cursor: pointer;

          @media screen and (max-width: 768px) {
            max-width: 100%;
          }

          &.text {
            max-width: none;
            font-size: 14px;
            line-height: 100%;
            text-transform: uppercase;
            color: #2F57E9;
            font-family: SemiBold;
            transition: .2s;

            &:hover {
              color: rgb(var(--color-light-blue));
            }
          }
        }
      }
    }

    .form-page__grid__end {
      position: relative;

      .form-page__grid__end__sticky {
        position: sticky;
        top: 20px;

        @media screen and (max-width: 1280px) {
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
}
</style>
